<template>
  <div>
    <loading v-if="loading" />
    <v-card v-else :outlined="$vuetify.theme.dark">
      <v-card-text>
        <!-- title -->
        <div class="d-flex align-center" style="gap: 10px">
          <v-btn icon @click="goBack">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <div class="title">Add Case</div>
        </div>

        <!-- form -->
        <v-form class="py-2" lazy-validation ref="form">
          <!-- client -->
          <div class="mt-6">
            <div v-if="$route.query.client">
              <div class="sub-title pl-6 py-4">
                <div v-if="client_detail.name">
                  {{ client_detail.name }}
                </div>
                <div style="opacity: 0.6" v-if="client_detail.user">
                  {{ client_detail.user.mobile_display }} |
                  {{ client_detail.user.email }}
                </div>
              </div>
            </div>

            <v-sheet v-else width="32%">
              <v-autocomplete
                auto-select-first
                :rules="[$rules.required]"
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                }"
                append-icon="mdi-chevron-down"
                v-model="form.client"
                :items="client_list"
                :search-input.sync="client_search"
                :loading="client_list_loading"
                item-text="name"
                item-value="id"
                flat
                outlined
                dense
                no-filter
                label="Select Client"
              >
                <template v-slot:selection="data">
                  <div v-if="data.item.name">
                    {{ data.item.name }}
                  </div>
                  <div v-else-if="data.item.user.mobile">
                    {{ data.item.user.mobile_display }}
                  </div>
                  <div v-else>
                    {{ data.item.user.email }}
                  </div>
                </template>

                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title v-if="data.item.name">
                      {{ data.item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span v-if="data.item.user.mobile"
                        >{{ data.item.user.mobile_display }} |</span
                      >
                      {{ data.item.user.email }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <template v-slot:no-data>
                  <div class="px-2 mx-3 my-1 text-caption">
                    <span v-if="client_list_loading">Searching...</span>
                    <span
                      v-else
                      class="
                        d-flex
                        align-center
                        primary--text
                        pointer
                        font-weight-bold
                      "
                      style="gap: 5px"
                      @click="
                        LeadsModal({
                          show: true,
                          id: null,
                          type: 'add',
                        })
                      "
                      ><v-icon size="18" color="primary">mdi-plus</v-icon>
                      <span>Add Client</span>
                    </span>
                  </div>
                </template>
              </v-autocomplete>
            </v-sheet>
          </div>

          <v-card outlined rounded="lg">
            <!-- ****Start Basic Information***** -->

            <div class="section-name pa-5">Basic Information</div>
            <div class="d-flex flex-wrap mx-5" style="gap: 0px 20px">
              <v-sheet width="32%">
                <v-select
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  append-icon="mdi-chevron-down"
                  :rules="[$rules.required]"
                  v-model="form.service_type"
                  :items="service_types"
                  @change="onServiceTypeChange"
                  item-text="value"
                  item-value="key"
                  return-object
                  flat
                  outlined
                  dense
                  label="Service Type"
                ></v-select>
              </v-sheet>
              <v-sheet width="32%">
                <v-autocomplete
                  auto-select-first
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  append-icon="mdi-chevron-down"
                  :rules="[$rules.required]"
                  v-model="form.service"
                  :items="service_list"
                  @change="onServiceChange"
                  item-text="name"
                  item-value="id"
                  return-object
                  flat
                  outlined
                  dense
                  label="Select Service"
                ></v-autocomplete>
              </v-sheet>

              <!-- state -->
              <v-sheet width="32%" v-if="hasPermission($keys.STATE)">
                <v-autocomplete
                  auto-select-first
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  append-icon="mdi-chevron-down"
                  v-model="form.state"
                  @change="onStateChange"
                  :items="state_list"
                  :rules="getFieldRules($keys.STATE)"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  dense
                  clearable
                  :label="getFieldLabel($keys.STATE)"
                >
                  <template v-slot:no-data>
                    <v-btn
                      text
                      class="primary--text px-2"
                      block
                      @click="openAddStateDialog"
                    >
                      <v-icon size="18">mdi-plus</v-icon> Add State
                    </v-btn>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="data.item.country">
                        {{ data.item.country.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-sheet>

              <!-- city -->
              <v-sheet width="32%" v-if="hasPermission($keys.CITY)">
                <v-autocomplete
                  auto-select-first
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  append-icon="mdi-chevron-down"
                  v-model="form.city"
                  @change="onCityChange"
                  :items="city_list"
                  :rules="getFieldRules($keys.CITY)"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  dense
                  clearable
                  :label="getFieldLabel($keys.CITY)"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="data.item.state">
                        {{ data.item.state.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-sheet>

              <!-- university -->
              <v-sheet width="32%" v-if="hasPermission($keys.UNIVERSITY)">
                <v-autocomplete
                  auto-select-first
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  :rules="getFieldRules($keys.UNIVERSITY)"
                  append-icon="mdi-chevron-down"
                  v-model="form.university"
                  :items="university_list"
                  @change="onUniversityChange"
                  :search-input.sync="university_search"
                  :loading="university_list_loading"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  dense
                  no-filter
                  :label="getFieldLabel($keys.UNIVERSITY)"
                >
                  <template v-slot:no-data>
                    <div class="mx-3 my-1 text-caption">
                      <span v-if="university_list_loading">Searching...</span>
                      <span v-else> No data available </span>
                    </div>
                  </template>
                </v-autocomplete>
              </v-sheet>

              <!-- education -->
              <v-sheet width="32%" v-if="hasPermission($keys.EDUCATION)">
                <v-autocomplete
                  auto-select-first
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  :rules="getFieldRules($keys.EDUCATION)"
                  append-icon="mdi-chevron-down"
                  v-model="form.education"
                  :items="education_list"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  dense
                  :label="getFieldLabel($keys.EDUCATION)"
                ></v-autocomplete>
              </v-sheet>

              <!-- course -->
              <v-sheet width="32%" v-if="hasPermission($keys.COURSE)">
                <v-autocomplete
                  auto-select-first
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  :rules="getFieldRules($keys.COURSE)"
                  append-icon="mdi-chevron-down"
                  v-model="form.course"
                  :items="course_list"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  dense
                  :label="getFieldLabel($keys.COURSE)"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name }}
                        <span v-if="data.item.abbreviation"
                          >({{ data.item.abbreviation }})</span
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-sheet>

              <!-- no. of sets -->
              <v-sheet width="32%" v-if="hasPermission($keys.NO_OF_SETS)">
                <v-text-field
                  type="number"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.NO_OF_SETS)"
                  @input="validateNegativeNumber($keys.NO_OF_SETS)"
                  v-model="form.no_of_sets"
                  min="1"
                  flat
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.NO_OF_SETS)"
                ></v-text-field>
              </v-sheet>

              <!-- no. of documents -->
              <v-sheet width="32%" v-if="hasPermission($keys.NO_OF_DOCUMENTS)">
                <v-text-field
                  type="number"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.NO_OF_DOCUMENTS)"
                  @input="validateNegativeNumber($keys.NO_OF_DOCUMENTS)"
                  v-model="form.no_of_documents"
                  min="1"
                  flat
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.NO_OF_DOCUMENTS)"
                ></v-text-field>
              </v-sheet>

              <!-- document type -->
              <v-sheet width="32%" v-if="hasPermission($keys.DOCUMENT_TYPE)">
                <v-select
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  :rules="getFieldRules($keys.DOCUMENT_TYPE)"
                  append-icon="mdi-chevron-down"
                  v-model="form.document_type"
                  :items="$keys.DOCUMENT_TYPES"
                  item-text="label"
                  item-value="key"
                  flat
                  outlined
                  dense
                  :label="getFieldLabel($keys.DOCUMENT_TYPE)"
                ></v-select>
              </v-sheet>

              <!-- delivery type -->
              <v-sheet width="32%" v-if="hasPermission($keys.DELIVERY_TYPE)">
                <v-select
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  :rules="getFieldRules($keys.DELIVERY_TYPE)"
                  append-icon="mdi-chevron-down"
                  v-model="form.delivery_type"
                  :items="$keys.DELIVERY_TYPES"
                  item-text="label"
                  item-value="key"
                  flat
                  outlined
                  dense
                  :label="getFieldLabel($keys.DELIVERY_TYPE)"
                ></v-select>
              </v-sheet>

              <!-- send to eca -->
              <v-sheet width="32%" v-if="hasPermission($keys.SEND_TO_ECA)">
                <v-checkbox
                  class="my-2"
                  v-model="form.send_to_eca"
                  :label="getFieldLabel($keys.SEND_TO_ECA)"
                ></v-checkbox>
              </v-sheet>

              <!-- eca -->
              <v-sheet
                width="32%"
                v-if="hasPermission($keys.ECA) || form.send_to_eca"
              >
                <v-autocomplete
                  auto-select-first
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  append-icon="mdi-chevron-down"
                  :rules="getFieldRules($keys.ECA)"
                  v-model="form.eca"
                  :items="eca_body_list"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  dense
                  multiple
                  :label="getFieldLabel($keys.ECA)"
                ></v-autocomplete>
              </v-sheet>

              <!-- eca_reference_number -->
              <v-sheet
                width="32%"
                v-if="
                  hasPermission($keys.ECA_REFERENCE_NUMBER) || form.send_to_eca
                "
              >
                <v-text-field
                  type="text"
                  :rules="getFieldRules($keys.ECA_REFERENCE_NUMBER)"
                  v-model="form.eca_reference_number"
                  flat
                  outlined
                  dense
                  :label="getFieldLabel($keys.ECA_REFERENCE_NUMBER)"
                ></v-text-field>
              </v-sheet>
            </div>

            <v-divider />

            <!-- pricing -->
            <div
              class="
                section-name
                ma-5
                d-flex
                align-center
                justify-space-between
              "
            >
              <div class="d-flex align-center" style="gap: 20px">
                <div>Pricing Information</div>
                <v-btn
                  small
                  text
                  outlined
                  class="text-capitalize"
                  @click="getDefaultPricing"
                >
                  <v-icon small left>mdi-reload</v-icon>Get default pricing
                </v-btn>
              </div>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </template>
                <span v-if="defaultPricing && defaultPricing.info">
                  {{ defaultPricing.info }}
                </span>
                <span v-else>Info not available</span>
              </v-tooltip>
            </div>

            <div class="d-flex flex-wrap mx-5" style="gap: 0px 20px">
              <!-- university_cost -->
              <v-sheet width="32%" v-if="hasPermission($keys.UNIVERSITY_COST)">
                <v-text-field
                  v-model="form.university_cost"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.UNIVERSITY_COST)"
                  @input="validateNegativeNumber($keys.UNIVERSITY_COST)"
                  type="number"
                  flat
                  min="0"
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.UNIVERSITY_COST)"
                ></v-text-field>
              </v-sheet>

              <!-- university_cost_per_document -->
              <v-sheet
                width="32%"
                v-if="hasPermission($keys.UNIVERSITY_COST_PER_DOCUMENT)"
              >
                <v-text-field
                  v-model="form.university_cost_per_document"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.UNIVERSITY_COST_PER_DOCUMENT)"
                  @input="
                    validateNegativeNumber($keys.UNIVERSITY_COST_PER_DOCUMENT)
                  "
                  type="number"
                  flat
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.UNIVERSITY_COST_PER_DOCUMENT)"
                ></v-text-field>
              </v-sheet>

              <!-- university_additional_cost -->
              <v-sheet
                width="32%"
                v-if="hasPermission($keys.UNIVERSITY_ADDITIONAL_COST)"
              >
                <v-text-field
                  v-model="form.university_additional_cost"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.UNIVERSITY_ADDITIONAL_COST)"
                  @input="
                    validateNegativeNumber($keys.UNIVERSITY_ADDITIONAL_COST)
                  "
                  type="number"
                  flat
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.UNIVERSITY_ADDITIONAL_COST)"
                ></v-text-field>
              </v-sheet>

              <!-- processing_cost -->
              <v-sheet width="32%" v-if="hasPermission($keys.PROCESSING_COST)">
                <v-text-field
                  v-model="form.processing_cost"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.PROCESSING_COST)"
                  @input="validateNegativeNumber($keys.PROCESSING_COST)"
                  type="number"
                  flat
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.PROCESSING_COST)"
                ></v-text-field>
              </v-sheet>

              <!-- processing_cost_per_copy -->
              <v-sheet
                width="32%"
                v-if="hasPermission($keys.PROCESSING_COST_PER_COPY)"
              >
                <v-text-field
                  v-model="form.processing_cost_per_copy"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.PROCESSING_COST_PER_COPY)"
                  @input="
                    validateNegativeNumber($keys.PROCESSING_COST_PER_COPY)
                  "
                  type="number"
                  flat
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.PROCESSING_COST_PER_COPY)"
                ></v-text-field>
              </v-sheet>

              <!-- processing_cost_per_document -->
              <v-sheet
                width="32%"
                v-if="hasPermission($keys.PROCESSING_COST_PER_DOCUMENT)"
              >
                <v-text-field
                  v-model="form.processing_cost_per_document"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.PROCESSING_COST_PER_DOCUMENT)"
                  @input="
                    validateNegativeNumber($keys.PROCESSING_COST_PER_DOCUMENT)
                  "
                  type="number"
                  flat
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.PROCESSING_COST_PER_DOCUMENT)"
                ></v-text-field>
              </v-sheet>

              <!-- processing_additional_cost -->
              <v-sheet
                width="32%"
                v-if="hasPermission($keys.PROCESSING_ADDITIONAL_COST)"
              >
                <v-text-field
                  v-model="form.processing_additional_cost"
                  :rules="getFieldRules($keys.PROCESSING_ADDITIONAL_COST)"
                  @input="
                    validateNegativeNumber($keys.PROCESSING_ADDITIONAL_COST)
                  "
                  type="number"
                  flat
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.PROCESSING_ADDITIONAL_COST)"
                ></v-text-field>
              </v-sheet>

              <!-- shipping_cost -->
              <v-sheet width="32%" v-if="hasPermission($keys.SHIPPING_COST)">
                <v-text-field
                  v-model="form.shipping_cost"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.SHIPPING_COST)"
                  @input="validateNegativeNumber($keys.SHIPPING_COST)"
                  type="number"
                  flat
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.SHIPPING_COST)"
                ></v-text-field>
              </v-sheet>

              <!-- discount_percent -->
              <v-sheet width="32%" v-if="hasPermission($keys.DISCOUNT_PERCENT)">
                <v-text-field
                  v-model="form.discount_percent"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.DISCOUNT_PERCENT)"
                  @input="validateNegativeNumber($keys.DISCOUNT_PERCENT)"
                  type="number"
                  flat
                  dense
                  outlined
                  hide-spin-buttons
                  :label="getFieldLabel($keys.DISCOUNT_PERCENT)"
                ></v-text-field>
              </v-sheet>


              <v-sheet width="32%"></v-sheet>

              <!-- Is gst Applicable -->
              <v-sheet width="32%">
                 <v-checkbox hide-details
                  v-model="is_gst_applicable"
                  @change="is_gst_applicable?form.gst_percent=18:form.gst_percent=0"
                  label="Is GST Applicable"
                ></v-checkbox>
              </v-sheet>

              <!-- gst_percent -->
              <v-sheet width="32%" v-if="is_gst_applicable">
                <v-text-field
                  v-model="form.gst_percent"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.GST_PERCENT)"
                  @input="validateNegativeNumber($keys.GST_PERCENT)"
                  type="number" disabled
                  flat
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.GST_PERCENT)"
                ></v-text-field>
              </v-sheet>
            </div>

            <!-- computed price -->
            <v-sheet
              outlined
              rounded="lg"
              class="d-flex align-center justify-end pa-3 ma-4"
            >
              <div
                class="text-right"
                v-if="hasPermission($keys.TOTAL_UNIVERSITY_COST)"
              >
                <div class="text--secondary">Total University Cost</div>
                <div class="text-capitalize">₹ {{ total_university_cost }}</div>
              </div>
              <v-divider
                vertical
                class="mx-4"
                v-if="hasPermission($keys.TOTAL_UNIVERSITY_COST)"
              ></v-divider>

              <div class="text-right">
                <div class="text--secondary">Total Processing Cost</div>
                <div class="text-capitalize">₹ {{ total_processing_cost }}</div>
              </div>

              <v-divider vertical class="mx-4"></v-divider>

              <div class="text-right">
                <div class="text--secondary">GST Amount</div>
                <div class="text-capitalize">₹ {{ gst_amount }}</div>
              </div>

              <v-divider vertical class="mx-4"></v-divider>

              <div class="text-right">
                <div class="text--secondary">Discount Amount</div>
                <div class="text-capitalize">₹ {{ discount_amount }}</div>
              </div>

              <v-divider vertical class="mx-4"></v-divider>

              <div class="text-right">
                <div class="text--secondary">Total Amount</div>
                <div class="text-capitalize">₹ {{ total_amount }}</div>
              </div>
            </v-sheet>

            <v-divider />

            <!-- tat -->
            <div>
              <div class="section-name pa-5">TAT (turnaround time)</div>
              <div class="d-flex flex-wrap mx-5" style="gap: 0px 20px">
                <!-- client_tat_days -->
                <v-sheet width="32%">
                  <v-text-field
                    v-model="form.client_tat_days"
                    type="number"
                    dense
                    @keydown="$rules.blockInvalidChar"
                    :rules="getFieldRules($keys.CLIENT_TAT_DAYS)"
                    @input="validateNegativeNumber($keys.CLIENT_TAT_DAYS)"
                    outlined
                    hide-spin-buttons
                    label="Client TAT"
                    hint="In Days"
                    persistent-hint
                  ></v-text-field>
                </v-sheet>

                <!-- internal_tat_days -->
                <v-sheet width="32%">
                  <v-text-field
                    v-model="form.internal_tat_days"
                    type="number"
                    dense
                    @keydown="$rules.blockInvalidChar"
                    :rules="getFieldRules($keys.INTERNAL_TAT_DAYS)"
                    @input="validateNegativeNumber($keys.INTERNAL_TAT_DAYS)"
                    outlined
                    hide-spin-buttons
                    label="Internal TAT"
                    hint="In Days"
                    persistent-hint
                  ></v-text-field>
                </v-sheet>
              </div>
            </div>
          </v-card>

          <!-- remarks -->
          <div class="mt-6">
            <v-textarea
              outlined
              v-model="form.remarks"
              :rules="[$rules.required]"
              label="Internal Remarks"
            >
            </v-textarea>
          </div>
        </v-form>

        <!-- actions -->
        <div class="mt-4 d-flex justify-start" style="gap: 16px">
          <v-btn
            color="primary"
            class="text-capitalize px-10"
            height="48px"
            @click="submit('save')"
            :loading="save_btn"
          >
            <span>Save</span>
          </v-btn>
          <v-btn
            color="primary"
            outlined
            class="text-capitalize"
            height="48px"
            :loading="save_send_quotation_btn"
            @click="submit('save_send_quotation')"
          >
            <span>Save & Send Quotation</span>
          </v-btn>
          <v-btn
            color="primary"
            outlined
            class="text-capitalize"
            height="48px"
            :loading="save_add_more_btn"
            @click="submit('save_add_more')"
          >
            <span>Save & Add More Case</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <add-leads-modal @submitted="getClientDetail" />

    <!-- send quotation dialog -->
    <send-quotation-dialog />
    <StateAddEditModal @on-submit="onStateAdded" />
  </div>
</template>
<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import addLeadsModal from "../clientManagement/addLeadsModal.vue";
import SendQuotationDialog from "@/components/Dialogs/SendQuotationDialog";
import StateAddEditModal from "@/components/admin/StateAddEditModal";
export default {
  components: { addLeadsModal, SendQuotationDialog, StateAddEditModal },
  data() {
    return {
      loading: false,

      client_list: [],
      client_search: "",
      client_list_loading: false,

      service_list: [],
      education_list: [],
      course_list: [],
      city_list: [],

      university_list: [],
      university_search: "",
      university_list_loading: false,

      eca_body_list: [],
      client_detail: {},

      save_add_more_btn: false,
      save_send_quotation_btn: false,
      save_btn: false,

      defaultPricing: null,

      is_gst_applicable:true,

      form: {
        // basic info
        client: null,
        service_type: null,
        service: null,
        state: null,
        city: null,
        university: null,
        education: null,
        course: null,
        no_of_sets: 1,
        no_of_documents: 1,
        send_to_eca: false,
        eca: [],
        eca_reference_number: "",
        document_type: this.$keys.EDUCATIONAL,
        delivery_type: this.$keys.E_SHIPMENT,

        // pricing
        university_cost: 0,
        university_cost_per_document: 0,
        university_additional_cost: 0,
        processing_cost: 0,
        processing_cost_per_copy: 0,
        processing_cost_per_document: 0,
        processing_additional_cost: 0,
        shipping_cost: 0,
        gst_percent: 18,
        discount_percent: 0,

        // tat
        internal_tat_days: 0,
        client_tat_days: 0,

        remarks: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      user: "getUser",
      state_list: "getStateList",
      cityList: "getCityList",
      service_types: "getServiceTypes",
    }),

    total_university_cost() {
      return (
        (Number(this.form.university_cost) +
          Number(this.form.university_cost_per_document) *
            Number(this.form.no_of_documents)) *
          Number(this.form.no_of_sets) +
        Number(this.form.university_additional_cost)
      );
    },

    total_processing_cost() {
      return (
        Number(this.form.processing_cost) +
        Number(this.form.processing_cost_per_copy) *
          (Number(this.form.no_of_sets) - 1) +
        Number(this.form.processing_cost_per_document) *
          Number(this.form.no_of_documents) +
        Number(this.form.processing_additional_cost)
      );
    },

    gst_amount() {
      return (
        (Number(
          this.total_processing_cost + this.form.university_additional_cost
        ) *
          Number(this.form.gst_percent)) /
        100
      );
    },

    discount_amount() {
      return (
        (Number(this.total_processing_cost) *
          Number(this.form.discount_percent)) /
        100
      );
    },

    investment_cost() {
      return (
        Number(this.total_university_cost) +
        Number(this.total_processing_cost) +
        Number(this.form.shipping_cost)
      );
    },

    total_amount() {
      return (
        Number(this.investment_cost) +
        Number(this.gst_amount) -
        Number(this.discount_amount)
      );
    },
  },

  watch: {
    client_search: _.debounce(function (query) {
      if (this.client_list_loading) return;

      if (query && !this.form.client) {
        this.getClientList();
      }
    }, 1000),

    university_search: _.debounce(function (query) {
      if (this.university_list_loading) return;

      if (query && !this.form.university) {
        this.getUniversityList();
      }
    }, 1000),

    defaultPricing(value) {
      this.form.university_cost = value.university_cost;
      this.form.university_cost_per_document =
        value.university_cost_per_document;
      this.form.university_additional_cost = value.university_additional_cost;
      this.form.processing_cost = value.processing_cost;
      this.form.processing_cost_per_copy = value.processing_cost_per_copy;
      this.form.processing_cost_per_document =
        value.processing_cost_per_document;
      this.form.gst_percent = value.gst_percent;
      this.form.internal_tat_days = value.internal_tat_days;
      this.form.client_tat_days = value.client_tat_days;
    },

    "form.service_type"() {
      this.getServiceList();
    },

    "form.send_to_eca"() {
      this.form.eca = [];
      this.form.eca_reference_number = "";
    },

    "form.course": function (value) {
      if (_.find(this.course_list, ["id", value])) {
        this.form.no_of_documents = _.find(this.course_list, [
          "id",
          value,
        ]).no_of_documents;
      }
    },
  },

  async mounted() {
    this.loading = true;

    if (this.$route.query.client) {
      await this.getClientDetail(this.$route.query.client);
      this.form.client = this.$route.query.client;
    }

    await this.getEducationList();
    await this.getCourseList();
    await this.getEcaBodyList();
    await this.getDefaultPricing();

    this.form.service_type = this.service_types[0];
    this.city_list = this.cityList;
    this.loading = false;
  },

  methods: {
    ...mapActions({
      showSnackbar: "snackBar/showSnackbar",
      LeadsModal: "clientManagement/LeadsModal",
      toggleSendQuotationDialog: "dialogs/toggleSendQuotationDialog",
      toggleAddEditStateDialog: "stateManagement/toggleDialog",
    }),

    hasPermission(field) {
      const service_type = this.form.service_type;

      if (service_type) {
        const _field = this.$_.find(service_type.form_fields, { key: field });
        return _field ? _field.show : false;
      }

      return false;
    },

    getFieldLabel(field) {
      const service_type = this.form.service_type;

      if (service_type) {
        const _field = this.$_.find(service_type.form_fields, { key: field });
        return _field ? _field.label : false;
      }

      return "";
    },

    getFieldRequired(field) {
      const service_type = this.form.service_type;

      if (service_type) {
        const _field = this.$_.find(service_type.form_fields, { key: field });
        return _field ? _field.required : false;
      }

      return false;
    },

    getFieldRules(field) {
      const _rules = [];

      if (this.getFieldRequired(field)) {
        _rules.push(this.$rules.required);
      }

      if (field === this.$keys.ECA && this.form.send_to_eca) {
        _rules.push(this.$rules.required);
      }

      if (
        [
          this.$keys.NO_OF_SETS,
          this.$keys.NO_OF_DOCUMENTS,
          this.$keys.UNIVERSITY_COST,
          this.$keys.UNIVERSITY_COST_PER_DOCUMENT,
          this.$keys.UNIVERSITY_ADDITIONAL_COST,
          this.$keys.PROCESSING_COST,
          this.$keys.PROCESSING_COST_PER_COPY,
          this.$keys.PROCESSING_COST_PER_DOCUMENT,
          this.$keys.PROCESSING_ADDITIONAL_COST,
          this.$keys.SHIPPING_COST,
          this.$keys.GST_PERCENT,
          this.$keys.DISCOUNT_PERCENT,

          this.$keys.CLIENT_TAT_DAYS,
          this.$keys.INTERNAL_TAT_DAYS,
        ].includes(field)
      ) {
        _rules.push(this.$rules.validateNegativeNumber);
      }

      return _rules;
    },

    validateNegativeNumber(field) {
      let value = this.form[field];
      value = Number(value);

      if (value < 0) {
        this.form[field] = Number(0);
      } else this.form[field] = value;
    },

    onServiceTypeChange() {
      this.form.service = null;
      this.form.state = null;
      this.form.city = null;
      this.form.university = null;
      this.form.education = null;
      this.form.course = null;
      this.form.no_of_sets = 1;
      this.form.no_of_documents = 1;
      this.form.send_to_eca = false;
      this.form.eca_body = null;
      this.form.eca_reference_number = "";
      this.form.document_type = this.$keys.EDUCATIONAL;
      this.form.delivery_type = this.$keys.E_SHIPMENT;

      // pricing
      this.form.university_cost = 0;
      this.form.university_cost_per_document = 0;
      this.form.university_additional_cost = 0;
      this.form.processing_cost = 0;
      this.form.processing_cost_per_copy = 0;
      this.form.processing_cost_per_document = 0;
      this.form.processing_additional_cost = 0;
      this.form.shipping_cost = 0;
      this.form.gst_percent = 0;
      this.form.discount_percent = 0;

      this.getServiceList();
      this.getDefaultPricing();
    },

    onServiceChange() {
      this.getDefaultPricing();
    },

    goBack() {
      this.$router.push({ name: "caseList" });
    },

    getClientList() {
      this.client_list_loading = true;

      const params = {
        list_type: "add_case",
        search: this.client_search,
        query: "{id, user {id, email, mobile, mobile_display}, name}",
      };

      const onSuccess = (res) => {
        this.client_list = res.data.data;
      };

      const onFinally = () => {
        this.client_list_loading = false;
      };

      this.$request(this.$keys.GET, this.$urls.client.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    getServiceList() {
      const onSuccess = (res) => {
        this.service_list = res.data.data;
      };

      const params = {
        apply_pagination: false,
      };
      params["service_type"] = this.form.service_type.key;

      return this.$request(this.$keys.GET, this.$urls.master.service.list, {
        params,
        onSuccess,
      });
    },

    getCityList() {
      const params = {
        apply_pagination: false,
        state_id: this.form.state,
      };

      const onSuccess = (res) => {
        this.city_list = res.data.data;
      };

      this.$request(this.$keys.GET, this.$urls.master.city.list, {
        params,
        onSuccess,
      });
    },

    getEducationList() {
      const onSuccess = (res) => {
        this.education_list = res.data.data;
      };

      const params = {
        apply_pagination: false,
      };

      return this.$request(this.$keys.GET, this.$urls.master.education.list, {
        onSuccess,
        params,
      });
    },

    getCourseList() {
      const onSuccess = (res) => {
        this.course_list = res.data.data;
      };

      const params = {
        apply_pagination: false,
      };

      return this.$request(this.$keys.GET, this.$urls.master.course.list, {
        onSuccess,
        params,
      });
    },

    getUniversityList() {
      this.university_list_loading = true;

      const onSuccess = (res) => {
        this.university_list = res.data.data;
      };

      const onFinally = () => {
        this.university_list_loading = false;
      };

      const params = {
        search: this.university_search,
      };

      if (this.form.state) {
        params["state_id"] = this.form.state;
      }

      return this.$request(this.$keys.GET, this.$urls.university.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    getEcaBodyList() {
      const onSuccess = (res) => {
        this.eca_body_list = res.data.data;
      };

      const params = {
        apply_pagination: false,
      };

      return this.$request(this.$keys.GET, this.$urls.eca.list, {
        params,
        onSuccess,
      });
    },

    getDefaultPricing() {
      const onSuccess = (res) => {
        this.defaultPricing = res.data.data;
      };

      const params = {
        service_id: this.form.service ? this.form.service.id : null,
        university_id: this.form.university,
      };

      return this.$request(this.$keys.GET, this.$urls.case.defaultPricing, {
        params,
        onSuccess,
      });
    },

    getClientDetail(client_id) {
      const params = {
        client_id: client_id,
      };

      const successHandler = (res) => {
        this.client_detail = res.data.data;
      };

      return this.$request(this.$keys.GET, this.$urls.client.detail, {
        params: params,
        onSuccess: successHandler,
      });
    },

    submit(action) {
      if (this.$refs.form.validate()) {
        switch (action) {
          case "save":
            this.save_btn = true;
            break;
          case "save_send_quotation":
            this.save_send_quotation_btn = true;
            break;
          case "save_add_more":
            this.save_add_more_btn = true;
            break;
        }

        const onSuccess = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });

         switch (action) {
             case "save_send_quotation":
               this.toggleSendQuotationDialog({
                show: true,
                caseId: res.data.data.id,
                clientId: res.data.data.client.id,
                moveTo:'caseDetail'
              });
              //  this.$router.push({
              //   name: "caseDetail",
              //   params: { id: res.data.data.id },
              // });
              break;
            case "save_add_more":
              window.scrollTo(0, 0);
              this.$router.go();
              break;
            default:
              this.$router.push({
                name: "caseDetail",
                params: { id: res.data.data.id },
              });
          }
        };

        const onFinally = () => {
          this.save_btn = false;
          this.save_send_quotation_btn = false;
          this.save_add_more_btn = false;
        };

        const formData = {};
        const form = this.form;

        // basic info
        formData["client"] = form.client;
        formData["service"] = form.service.id;
        formData["state"] = form.state;
        formData["city"] = form.city;
        formData["university"] = form.university;
        formData["education"] = form.education;
        formData["course"] = form.course;
        formData["no_of_sets"] = form.no_of_sets;
        formData["no_of_documents"] = form.no_of_documents;
        formData["send_to_eca"] = form.send_to_eca;
        formData["eca"] = form.eca;
        formData["eca_reference_number"] = form.eca_reference_number;
        formData["document_type"] = form.document_type;
        formData["delivery_type"] = form.delivery_type;

        // pricing
        formData["university_cost"] = form.university_cost;
        formData["university_cost_per_document"] =
          form.university_cost_per_document;
        formData["university_additional_cost"] =
          form.university_additional_cost;
        formData["processing_cost"] = form.processing_cost;
        formData["processing_cost_per_copy"] = form.processing_cost_per_copy;
        formData["processing_cost_per_document"] =
          form.processing_cost_per_document;
        formData["processing_additional_cost"] =
          form.processing_additional_cost;
        formData["shipping_cost"] = form.shipping_cost;
        formData["gst_percent"] = form.gst_percent;
        formData["discount_percent"] = form.discount_percent;

        formData["remarks"] = form.remarks;

        // tat
        formData["client_tat_days"] = form.client_tat_days;
        formData["internal_tat_days"] = form.internal_tat_days;

        return this.$request(this.$keys.POST, this.$urls.case.create, {
          data: formData,
          onSuccess,
          onFinally,
        });
      } else {
        this.$nextTick(() => {
          const el = document.getElementsByClassName("v-input error--text");
          if (el) this.$vuetify.goTo(el[0], { offset: 50 });
          return;
        });
      }
    },

    onStateChange() {
      this.getCityList();
      this.form.city = null;

      this.getUniversityList();
      this.form.university = null;
    },

    onCityChange() {
      this.form.state = null;

      const selectedCity = _.find(this.city_list, {
        id: this.form.city,
      });

      if (selectedCity) {
        if (selectedCity.state) this.form.state = selectedCity.state.id;
      }
    },

    onUniversityChange() {
      this.getDefaultPricing();
      this.form.state = null;
      this.form.city = null;

      const selectedUniversity = _.find(this.university_list, {
        id: this.form.university,
      });

      if (selectedUniversity)
        if (selectedUniversity.city) {
          this.form.city = selectedUniversity.city.id;
          if (selectedUniversity.city.state)
            this.form.state = selectedUniversity.city.state.id;
        }
    },

    openAddStateDialog() {
      this.toggleAddEditStateDialog({
        show: true,
        type: "add",
      });
    },

    onStateAdded(newState) {
      this.state_list.push(newState);
      this.form.state = newState.id;
    },
  },
};
</script>
<style scoped>
.main-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.01em;
}
.sub-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
.section-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  opacity: 0.8;
}
</style>
